import { Controller } from "@hotwired/stimulus";
import { Turbo } from "@hotwired/turbo-rails";

const log = (args) => console.log(args);

// Connects to data-controller="profile-search"
export default class extends Controller {
  static targets = ["topic", "form"];
  static values = {
    topics: Array,
  };

  refreshed(e) {
    log("refreshed");
    self.refreshing = false;
  }

  connect() {
    log("connecting");
    this.refreshed();
    this.topicTarget.value = "";
  }

  interceptEnter(e) {
    log("intercepting");
    if (e.keyCode === 13) {
      this.addTopic();
      e.preventDefault();
    }
  }

  chooseAction(e) {
    log("choosing");
    if (e.inputType === "insertReplacementText") {
      this.addTopic();
    } else {
      this.formTarget.requestSubmit();
    }
    e.preventDefault();
  }

  addTopic() {
    log("adding");
    refresh(this.topicsValue.concat(this.topicTarget.value.split(/,| /)));
  }

  removeTopic(e) {
    log("removing");
    refresh(this.topicsValue.filter((elem) => elem !== e.params.topic));
  }
}

const refresh = (topics) => {
  if (self.refreshing) return;
  log("refreshing");
  self.refreshing = true;
  const url = new URL(window.location.href);
  url.searchParams.set("topics", topics.join(","));
  Turbo.visit(url);
};
